import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppResizeService {
  _xpoint = new BehaviorSubject(0);
  _enabled = new BehaviorSubject(false)

  public xpoint$: Observable<number> = this._xpoint.asObservable();
  public enabled$: Observable<boolean> = this._enabled.asObservable();


  set xpoint(point: number) {
    this._xpoint.next(point);
  }

  get xpoint(): number {
    return this._xpoint.getValue();
  }

  set enabled(val: boolean) {
    this._enabled.next(val);
  }

  get enabled(): boolean {
    return this._enabled.getValue();
  }

  constructor() { }
}
