<div class="platform-wrapper" [ngClass]="{ bot_active: (appResize.enabled$ | async) }">
    <div class="app-container" #appContainer>
        <router-outlet></router-outlet>
    </div>
    <div class="grabber" [hidden]="(appResize.enabled$ | async) === false" vpAppResize [leftResize]="appContainer" [rightResize]="botContainer"></div>
    <div class="bot-container" #botContainer>
        <vp-gai-assistant #assistant [user]="$user" [instance]="$instance"></vp-gai-assistant>
    </div>
</div>

<vp-loading-screen></vp-loading-screen>
