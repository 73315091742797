<div class="chat-bot-panel " [hidden]="(appResize.enabled$ | async) === false">
  <div class="chat-header">
    <span class="icon icon-logo mr-2"></span><span class="chat-header-text"> Assistant</span>

    <button
      class="round outline"
      (click)="fullscreen()"
    >
      <span class="icon icon-enlarge "
      [ngClass]="{ 'icon-enlarge': !fullWindow, 'icon-shrink': fullWindow }"
      ></span>
    </button>
    <button
      class="round outline"
      (click)="close()"
    >
      <span class="icon icon-close"></span>
    </button>
  </div>
  <div class="chat-body">
    <perfect-scrollbar class="scroll-container ps" #scrollbar [config]="config" [scrollWindow]="false">
      <div class="messages-wrapper" >
  
        <div  *ngFor="let msg of messages; trackBy: trackByFn" [ngClass]="{'user-message': msg.user, 'bot-message': !msg.user}">
          <div class="avatar small" *ngIf="!msg.user">
            <span class="icon icon-logo mr-2"></span>
          </div>
          <div class="avatar small" *ngIf="msg.user">
            <span class="initials" [hidden]="!!profile.avatar">
              {{ profile.initials }}
            </span>
          </div>
          <div class="message">
            <markdown
              [data]="msg.text"
              [disableSanitizer]="true">
            </markdown>
          </div>
        
        </div>
        <div class="typing-indicator" *ngIf="typing">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
  <div class="chat-footer">
    <input class="form-control" type="text" placeholder="Type a message..." [(ngModel)]="message" (keyup.enter)="sendMessage()" />
    <button class="small" [disabled]="this.typing" (click)="sendMessage()">
      <span class="icon icon-sent"></span>
    </button>
  </div>
</div>
<button
    class="assistant-fab-button"
    (click)="assistantPopover.hide(); toggleAssistant()"
    [tooltip]="assistantTooltipTemplate"
    #assistantPopover="bs-tooltip"
    containerClass="assistant-tooltip"
    placement="right"
    [hidden]="appResize.enabled$ | async"
  >
  <span
      class="icon icon-vector"
  ></span>
</button>

<ng-template #assistantTooltipTemplate
  ><div>
    <div><span class="icon icon-vector"></span></div>
    <p>
      Have a programme related question? Ask Vantage Assistant to guide you to
      find the right answers.
    </p>
  </div>
</ng-template>
