import { Injectable, OnDestroy } from '@angular/core';  
import { BehaviorSubject, Subscription, timer } from 'rxjs';  
import { switchMap } from 'rxjs/operators';  

@Injectable({  
  providedIn: 'root',  
})  
export class LocalStorageService implements OnDestroy {  
  private keyWatchSubjects: { [key: string]: BehaviorSubject<any> } = {};  
  private checkInterval: number = 3000; // Check every 3 seconds  
  private previousValues: { [key: string]: any } = {};  
  private subscription: Subscription;  

  constructor() {  
    this.startCheckingLocalStorage();  
  }  

  private startCheckingLocalStorage() {  
    this.subscription = timer(0, this.checkInterval)  
      .pipe(switchMap(async () => this.checkForChanges()))  
      .subscribe(change => {  
        if (change) {  
          const { key, value } = change;  
          this.notifyKeyChange(key, value);  
        }  
      });  
  }  

  private checkForChanges() {  
    for (const key in this.keyWatchSubjects) {  
      if (this.keyWatchSubjects.hasOwnProperty(key)) {  
        const currentValue = this.getItem(key);  
        if (JSON.stringify(this.previousValues[key]) !== JSON.stringify(currentValue)) {  
          this.previousValues[key] = currentValue; // Update previous value  
          return { key, value: currentValue }; // Return the key that changed  
        }  
      }  
    }  
    return null; // No changes found  
  }  

  private notifyKeyChange(key: string, value: any) {  
    if (this.keyWatchSubjects[key]) {  
      this.keyWatchSubjects[key].next(value);  
    }  
  }  

  setItem(key: string, value: any): void {  
    localStorage.setItem(key, JSON.stringify(value));  
    this.previousValues[key] = value; // Update previous value  
    this.notifyKeyChange(key, value);  
  }  

  getItem(key: string): any {  
    const value = localStorage.getItem(key); 
    const parcedValue = value ? JSON.parse(value) : null;
    return parcedValue;  
  }  

  removeItem(key: string): void {  
    localStorage.removeItem(key);  
    delete this.previousValues[key]; // Remove from previous values  
    this.notifyKeyChange(key, null);  
  }  

  clear(): void {  
    localStorage.clear();  
    this.previousValues = {}; // Clear previous values  
    for (const key in this.keyWatchSubjects) {  
      if (this.keyWatchSubjects.hasOwnProperty(key)) {  
        this.notifyKeyChange(key, null);  
      }  
    }  
  }  

  watchKey(key: string) {  
    if (!this.keyWatchSubjects[key]) {  
      const initialValue = this.getItem(key);  
      this.previousValues[key] = initialValue; // Initialize previous value for the key  
      this.keyWatchSubjects[key] = new BehaviorSubject(initialValue);  

      // Emit initial value to the new subscriber  
      this.keyWatchSubjects[key].next(initialValue);  
    }  
    return this.keyWatchSubjects[key].asObservable();  
  }  

  ngOnDestroy() {  
    if (this.subscription) {  
      this.subscription.unsubscribe();  
    }  
    // Cleanup all subjects  
    for (const key in this.keyWatchSubjects) {  
      if (this.keyWatchSubjects.hasOwnProperty(key)) {  
        this.keyWatchSubjects[key].complete();  
      }  
    }  
  }  
}  