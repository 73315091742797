import { Directive, Input, HostListener, ElementRef } from '@angular/core';
import { AppResizeService } from './app-resize-service.service';
@Directive({
  selector: '[vpAppResize]',
})
export class AppResizeDirective {
  @Input('leftResize') leftElement: HTMLElement;
  @Input('rightResize') rightElement: HTMLElement;
  grabber = false;
  width: number;
  constructor(private appResizeService: AppResizeService, private el: ElementRef<HTMLElement>) {
    this.appResizeService.xpoint$.subscribe(val => {
      this.setWidth(window.window.innerWidth - val, val)
    })
  }
  @HostListener('window:resize', ['$event']) onResize(event) {
    this.width = event.target.innerWidth;
  }
  @HostListener('mousedown') onMouseDown() {
    this.grabber = true;
    this.el.nativeElement.classList.add('side-panel');
    document.body.style.cursor = 'e-resize';
  }

  @HostListener('window:mouseup') onMouseUp() {
    this.grabber = false;
    this.el.nativeElement.classList.remove('side-panel');
    document.body.style.cursor = 'default';
  }

  @HostListener('window:mousemove', ['$event']) onMouseMove(event: MouseEvent) {
    if (this.grabber) {
      event.preventDefault();
      const rightElWidth = (this.width || window.innerWidth) - event.clientX;
      const meetsConditions = rightElWidth <= (window.innerWidth / 2) && rightElWidth >=400;
      if(meetsConditions) {
        this.appResizeService.xpoint = event.clientX;
      }
    }
  }

  private setWidth(rightElWidth, leftElementWidt) {
    if(this.leftElement) {
      this.leftElement.style.flex = `1 0 ${leftElementWidt - 16}px`;
      this.rightElement.style.flex = `1 5 ${rightElWidth}px`;
    }
  }
}