import { AfterViewInit, Component, ElementRef, HostBinding, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { GaiAssistantService } from './gai-assistant.service';
import { BehaviorSubject, filter, interval, mergeMap, Observable, pipe, Subject, Subscription, switchMap, takeUntil, tap, timer } from 'rxjs';
import { AppResizeService } from '../directives/app-resize-service.service';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-om-perfect-scrollbar';
import { LocalStorageService } from '@vantage-platform/store/lib/utilits/local-storage.service';
import { AuthService } from '@vantage-platform/auth';
import { IInstance, IUser } from '@vantage-platform/store';

interface Message {
  text: string;
  user: boolean;
}

const GAI_ASSISTANT_KEY_PART = 'gai-assistant';
@Component({
  selector: 'vp-gai-assistant',
  templateUrl: './gai-assistant.component.html',
  styleUrls: ['./gai-assistant.component.scss']
})
export class GaiAssistantComponent implements OnInit, AfterViewInit {
  @Input() user: Observable<IUser>;
  @Input() instance: Observable<IInstance>;

  message = '';
  messages: Message[] = [];
  typing = false;
  @HostBinding('class.full-window') fullWindow = false;
  @HostBinding('class.visible') aiBotAvailable = false;

  public config: PerfectScrollbarConfigInterface = {};
  lsKey = GAI_ASSISTANT_KEY_PART;
  profile;
  
  _user: IUser;
  _instance: IInstance;

  @ViewChild(PerfectScrollbarComponent, {static: true}) scrollbar?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;
  destroy$ = new Subject<void>();
  watchSubscribe: Subscription;



  constructor(private assistantService: GaiAssistantService, public appResize: AppResizeService,
    private localStorage: LocalStorageService, private auth: AuthService
  ){

  }

  ngOnInit() {
    this.user.pipe(takeUntil(this.destroy$)).subscribe(user => {
      this._user = user;
    })

    this.instance.pipe(
      takeUntil(this.destroy$),
      filter(u => u && u.id !== this._user?.id),
      tap(instance => this._instance = instance),
      switchMap((instance) => this.assistantService.checkAssistantAvailability(instance.id)
      ))
      .subscribe((instanceAvailable) => {
        this.aiBotAvailable = false;
        if(instanceAvailable !== null) {
          this.aiBotAvailable = true;
          this._initBot(this._instance.id);
        }
      });

    if (this.auth.isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { family_name, given_name } = this.auth.user
        .idTokenClaims as any;
      const initials = `${given_name.substring(0, 1)}${family_name.substring(
        0,
        1
      )}`.toUpperCase();
      this.profile = {
        initials
      };
    }
  }

  ngAfterViewInit(): void {
    this._scrollToBot();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  sendMessage() {
    if (!this.typing && this.message.trim()) {
      
      this.messages.push({ text: this.message, user: true });
      this.localStorage.setItem(this.lsKey, this.messages);
      this._scrollToBot()

      this.assistantService.postMessage(this._instance.id, this._user.id, this.message).subscribe(res => {
        this.typing = true;
        const done = new Subject();
        interval(3000).pipe(
          mergeMap(() => this.assistantService.getMessage(this._instance.id, this._user.id, res['taskId']))
        ).pipe(takeUntil(done)).subscribe(ans => {
          if(ans["done"]) {
            this.typing = false;
            done.next(true);
            done.complete();
            this.messages.push({ text: ans['response'], user: false });
            this.localStorage.setItem(this.lsKey, this.messages);
            this._scrollToBot();
          }
        })
      
        this.message = '';
      });
    }
  }

  close() {
    this.appResize.xpoint = window.innerWidth;
    this.appResize.enabled = false;
  }

  fullscreen() {
    this.fullWindow = !this.fullWindow;
  }

  toggleAssistant() {
    this.appResize.enabled = true;
    this.appResize.xpoint = window.innerWidth - 416;
  }

  trackByFn(index) {
    return index; // or item.id
  }

  private _initBot(instanceId: number) {
    if (this.watchSubscribe) {
      this.watchSubscribe.unsubscribe();
    }
    this.lsKey = `${GAI_ASSISTANT_KEY_PART}_${instanceId}`;
    this.messages = this.localStorage.getItem(this.lsKey) || [];
    this.watchSubscribe = this.localStorage.watchKey(this.lsKey).subscribe((val) => {
      this.messages = val || [];
      this._scrollToBot();
    })
  }

  private _scrollToBot() {
    setTimeout(() => {
      this.scrollbar.directiveRef.scrollToBottom(0, 200);
    });
  }

}
