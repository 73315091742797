<div [hidden]="!auth.isLoggedIn">
  <vp-side-nav id="side-nav" #sideNav [service]="sidenavService"></vp-side-nav>
  <section id="page" [ngClass]="{ headerVisible: $isHeaderVisible | async }" class="page">
    <div class="router-container">
      <router-outlet></router-outlet>

      <ngx-spinner
        [fullScreen]="false"
        zIndex="0"
        bdColor="rgba(255,255,255,0.6)"
        type="none"
        class="focus-spinner"
        name="focus-spinner"
      >
        <div class="icon icon-focus"></div>
        <span class="spinner-msg">Focussing Your Selection</span>
      </ngx-spinner>
    </div>
    <!-- header placed in the end to give it's content's z-index higher priority -->
    <vp-header
      [appVersion]="version"
      [showInstanceSelector]="isDesktop$ | async"
    ></vp-header>
    <div
      *ngIf="sideNav.isToggled"
      class="vp-product-layout__overlay-mobile"
      (click)="sideNav.isToggled = false"
    ></div>
  </section>
  <!-- <vp-assistant *ngIf="showAssistant"></vp-assistant> -->

  <ngx-spinner
    bdColor="rgba(255,255,255,0.8)"
    type="ball-clip-rotate"
    color="rgba(37,52,247,.54)"
    name="app-loading"
  >
  </ngx-spinner>
</div>
