<div [hidden]="!auth.isLoggedIn">
  <section class="headerVisible">
    <div class="router-container">
      <router-outlet></router-outlet>
    </div>
    <!-- header placed in the end to give it's content's z-index higher priority -->
    <vp-header [appVersion]="version"></vp-header>
  </section>
  <!-- <vp-assistant></vp-assistant> -->
</div>
