import {
  AppState,
  getAppLoading,
  getLoaded,
  IInstance,
  instance,
  IUser,
  loadUser,
  user
} from '@vantage-platform/store';
import { Component, HostListener, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { Observable} from 'rxjs';
import {
  filter,
  take,
} from 'rxjs/operators';

import { AuthService, LOCAL_STORAGE, SIGN_IN_ACTION } from "@vantage-platform/auth";
import { RoutingStateService } from "@vantage-platform/store/lib/services/routing-state.service";
import { SideNavService } from '@vantage-platform/common-ui';
import { Store } from '@ngrx/store';
import { setTheme } from "ngx-bootstrap/utils";
import { HelpersService } from './shared/helpers.service';
import { Router } from '@angular/router';
import { AppResizeService } from 'libs/common-ui/src/lib/directives/app-resize-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [SideNavService],
})
export class AppComponent implements OnInit {
  title = 'vantage-focus-web-app';

  isIframe = false;
  $isLoaded: Observable<boolean>;
  $isAppLoading: Observable<boolean>;
  $user: Observable<IUser>;
  $instance: Observable<IInstance>;


  @HostListener('document:visibilitychange', ['$event'])
  appVisibility() {
    if (!document.hidden) {
      if (!this.auth.user && !!localStorage.getItem(SIGN_IN_ACTION)) {
        this.router.navigate(['/login']);
      } else if (this.auth.checkIfSessionExpired()) {
        this.auth.logOut(window.location.origin + this.router.url);
      }
    }
  }

  constructor(
    public auth: AuthService,
    private store: Store<AppState>,
    private routingStateService: RoutingStateService,
    private router: Router,
    private helpersService: HelpersService,
    public appResize: AppResizeService,
    @Inject(LOCAL_STORAGE) @Optional() private localStorage: Storage,
  ) {
    setTheme('bs4');
    this.$isLoaded = this.store.select(getLoaded);
    this.$isAppLoading = this.store.select(getAppLoading);
    this.$user = this.store.select(user);
    this.$instance = this.store.select(instance);

    this.isIframe = window !== window.parent && !window.opener;
    this.routingStateService.loadRouting();
  }

  ngOnInit() {
    if (this.auth.isLoggedIn) {
      this.store
        .pipe(
          filter((s) => !!s.router),
          take(1)
        )
        .subscribe((s) => {
          console.log(s);
          this.helpersService.initPageTitle();
          this.store.dispatch(loadUser());
        });
    }
  }
}
